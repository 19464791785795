import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import BannerCredit from '@components/BannerCredit';
import Title from '@components/Title';
import Link from '@components/Link';
// import CardCollection from '@components/CardCollection';
import CTABanner from '@components/CTABanner';
import '@styles/home.scss';

const MuseumFromHomeView = ({ data }) => (
  <>
    <div>
      <Container>
        <Row className="no-gutters">
          <Col md={12}>
            <BannerCredit text="" />
            <GatsbyImage
              alt={data.banner.description}
              className="d-block mx-auto"
              data-cy="gatsby-img"
              image={getImage(data.banner)}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={10}>
            <Title title="Museum From Home" classes="short-title" titleSize="h1" />
            <p>
              From Emily the two-headed turtle to ancient fossilized fish, we’ve
              been sharing snaps from the museum on social media with
              #MuseumFromHome.
            </p>
            <p>
              With these science tidbits, we hope to inspire you to think about
              projects, experiments, and fun that the museum provides from the
              comfort of your home.
            </p>
            <p>
              Follow us on&nbsp;
              <Link to="https://www.facebook.com/sciencemuseum">Facebook</Link>
              ,&nbsp;
              <Link to="https://twitter.com/sciencemuseummn">Twitter</Link>
              ,&nbsp;
              <Link to="https://www.instagram.com/sciencemuseummn/">
                Instagram
              </Link>
              ,&nbsp;
              <Link to="https://www.linkedin.com/company/science-museum-of-minnesota/">
                LinkedIn
              </Link>
              , or&nbsp;
              <Link to="https://www.pinterest.com/sciencemuseummn/">
                Pinterest
              </Link>
              &nbsp;to find even more behind the scenes and stellar science
              info. Have a request for our next #MuseumFromHome post? Send us a
              message on any of our social media platforms.
            </p>
            {/* <CardCollection cards={data.allMuseumVoicesCardsYaml.edges} /> */}
            <br />
          </Col>
        </Row>
      </Container>
      <CTABanner />
    </div>
  </>
);

MuseumFromHomeView.propTypes = {
  // TODO: Fix this eslint warning and remove whitelisting
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default MuseumFromHomeView;
