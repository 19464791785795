import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import StandardPageWrapper from '@templates/StandardPageWrapper';
import HeadSEO from '@components/Navigation/HeadSEO';
import MuseumFromHomeView from '@views/MuseumFromHomeView';

export const Head = () => (
  <HeadSEO
    pageTitle="Museum from home"
    pageDescription="From Emily the two-headed turtle to ancient fossilized fish, we’ve been sharing snaps from the museum on social media with #MuseumFromHome."
  />
);

const MuseumFromHomePage = () => {
  const data = useStaticQuery(graphql`
    query {
      banner: contentfulAsset(title: { eq: "museum-voices" }) {
        title
        description
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  `);

  return (
    <StandardPageWrapper>
      <MuseumFromHomeView data={data} />
    </StandardPageWrapper>
  );
};

export default MuseumFromHomePage;
